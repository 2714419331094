.barcodeScanner {
  background-color: black;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.barcodeScannerWithoutPerms {
  display: flex;
  background-color: black;
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
  justify-content: center;
  flex-direction: column;
}

.barcodeScanner .video-container {
  position: relative;
  width: 100%; /* Set the width of the container as needed */
  height: auto; /* Set the height of the container as needed */
}

.barcodeScanner video {
  width: 100%; /* Video should take up 100% of the available width */
  height: auto; /* Allow the video's aspect ratio to be maintained */
  display: block; /* Ensure the video is a block-level element */
}

.barcodeScanner canvas {
  position: absolute; /* Position the canvas absolutely */
  top: 0; /* Align the canvas to the top */
  left: 0; /* Align the canvas to the left */
  width: 100%; /* Canvas should take up 100% of the available width */
  height: auto; /* Allow the canvas's aspect ratio to be maintained */
  display: block; /* Ensure the canvas is a block-level element */
  z-index: 1; /* Set the canvas to be on top of the video */
}

.barcodeScanner .results {
  position: absolute; /* Position the canvas absolutely */
  bottom: 20; /* Align the canvas to the top */
  left: 0; /* Align the canvas to the left */
  width: 100%; /* Canvas should take up 100% of the available width */
  height: auto; /* Allow the canvas's aspect ratio to be maintained */
  display: block; /* Ensure the canvas is a block-level element */
  z-index: 1; /* Set the canvas to be on top of the video */
}
