.header {
    background-color: #1e1e1e;
    padding: 10px 10px 6px 10px;
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.header img {
    height: 60px;
}


.header-whitemode {
    background-color: #f3f3f3;
}

.menu-icon {
    font-size: 36px;
    width: 20%;
    cursor: pointer;
}

.burger-menu {
    position: absolute;
    right: 0;
    top: 50px;
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centrar horizontalmente */
    justify-content: center; /* Centrar verticalmente */
    text-align: center; /* Centrar el contenido del texto */
}

.burger-menu button {
    margin-top: 10px;
    cursor: pointer;
}

.MuiDrawer-paper {
    background-color: #1e1e1e !important;
    width: 40%;
}

.MuiListItemIcon-root {
    min-width: 39px !important;
}

.MuiTypography-root {
    overflow: hidden;
    text-overflow: ellipsis;
}

.logoContainer {
    display: flex;
    align-items: center;
}

.fastStockLogo {
    margin: 10% 0px 10% 20%;
    width: 50%;
    height: 50%;
}