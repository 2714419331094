
.scannedBarcode {
  position: absolute;
  bottom: 10px; /* Adjust the margin from the bottom as needed */
  left: 50%; /* Center the element horizontally */
  transform: translateX(-50%); /* Center the element horizontally */
  background-color: #424242; /* Gray background color */
  border-radius: 10px; /* Rounded corners */
  padding: 10px; /* Add padding for better styling */
  border-width: 0px;
  text-align: center;
  z-index: 2; /* Ensure it's on top of the video */
}

.scannedBarcode p {
  font-size: 18px;
  color: #ffffff;
  margin: 0;
}

.scannedBarcode .labelVerStock {
  font-size: 13px;
  text-align: left;
  color: #cecece;
  margin: 0;
}

.scannedBarcode .lupa {
  position: absolute;
  top: -20px; /* Position it in the top-right corner */
  right: -20px; /* Position it in the top-right corner */
  background-color: #424242; /* Transparent background */
  border: none;
  border-radius: 50%; /* Make it round */
  padding: 10px; /* Add padding for better styling */
  cursor: pointer;
}

.scannedBarcode .lupa::before {
  content: "🔍"; /* Unicode representation of a search icon (customize as needed) */
  font-size: 24px; /* Adjust the size of the icon */
  color: #000; /* Icon color (black) */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1em;
}
