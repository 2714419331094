
body {
  background-attachment: fixed !important;
  background: #010101;
  height: 100vh;
}

.background {
  height: 100%;
  /* background-image: url('./images/background.png'); */
  /* background-repeat: no-repeat;
  background-size: cover; */
  /* background: linear-gradient(#010101, #717171, #C8873C); */
}

.mainContainer {
  text-align: center;
  /* height: calc(100vh - 60px);  */
  height: 100%;
  /* overflow: auto; */
}

.encontraTuTalle {
  height: 8vh;
  width: 50%;
  margin: 10px 25% 10px 25%;
  background-image: url(./images/encontraTuTalle.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 76px); ;
}

.noStockContainer {
  height: calc(100vh - 76px); ;
}

.errorContainer {
  height: calc(100vh - 76px); ;
  padding: 20px;
}

.loader {
  border: 10px solid #f3f3f3; 
  border-top: 10px solid #f78633; 
  border-radius: 50%;
  margin-top: -200px;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.scanButtonContainer {
  z-index: 1;
  bottom: 15px;
  position: fixed;
  right: 15px;
}

.briefExplanatioContainer {
  margin-top: 200px;
  padding: 20px;
}

.authContainer {
  /* Set display to flex and align items and justify content to center */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 50%;
  /* Set height and width as needed */
  /* height: 100vh; */
}

.white-caret-input {
  caret-color: white;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

label {
  color: #ffffff;
}

p {
  color: #ffffff;
}

h2 {
  color: #ffffff;
  margin-bottom: 0px;
}

h3 {
  color: #ffffff;
}

h4 {
  color: #ffffff;
}

h5 {
  color: #ffffff;
}

h6 {
  color: #ffffff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* TENANT FORTER */

.background-forter {
  background: linear-gradient(#ffffff, #dbdbdb, #507f5d);
  /* font-family: 'Gotham', sans-serif; */
}

.loader-forter {
  border-top: 10px solid #008423; 
}
