.firebaseui-container {
  background: #ffffff00;
}

.loginInfoContainer {
  background: rgba(0,0,0,.5);
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginInfo {
  font-size: 14px;
  color: #555;
  font-style: italic;
  background-color: #f1f1f1;
  padding: 13px 35px 13px 35px;
  border-radius: 8px;
}