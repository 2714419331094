.stockContainer {
  text-align: left;
  overflow-x: auto;
  display: block;
  white-space: nowrap;
}

.stockContainer table {
  width: 100%;
  table-layout: auto;
}

.infoContainer {
  display: flex;
  width: 85%;
  margin-left: 12.5%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.infoContainer > * {
  flex: 1;
}

.filterColorContainer {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  margin-right: 15px;
  margin-bottom: -45px;
}

.colorLabel {
  margin-left: 15px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: center;
}

th {
  background-color: #f78633;
  color: white;
}


tr:nth-child(even) {
  background-color: rgb(242, 242, 242, 0.3);
}

tr:nth-child(odd) {
  background-color: rgba(242, 242, 242, 0.5);
}

th:first-child,
td:first-child {
  width: 150px; /* Adjust this width as needed */
  text-align: left;
  overflow: hidden;
}

.button {
  width: 40px;
  height: 40px;
  border: 2px solid #836969;
  /* border: 2px solid white; */
  box-shadow: 0 0 0 2px black;
  border-radius: 50%;
  margin-left: 5px;
}

.dropdown {
  background-color: rgb(86, 86, 86);
  color: white;
  height: 30px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid white;
  z-index: 2;
}

.dropdown option {
  background-color: black;
  color: white;
}

.dropdown:focus{
  outline-color: orange;
}

.total-row {
  color: #202426;
  font-weight: bold;
}

a.store-link {
  text-decoration: none;
  color: inherit;
  display: inline-flex;
  align-items: center;
}

a.store-link .map-icon {
  margin-left: 5px; /* Add some space between the text and the icon */
  width: 16px; /* Set the width of your icon */
  height: auto; /* Ensure the aspect ratio is maintained */
}